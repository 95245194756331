var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"light":"","persistent":"","max-width":"750px"},model:{value:(_vm.chkpointDialog),callback:function ($$v) {_vm.chkpointDialog=$$v},expression:"chkpointDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline px-2"},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0"},[_vm._v(" "+_vm._s(_vm.formData.id ? "Editar Checkpoint" : "Novo Checkpoint")+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleChkpointAdm}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"frmChkpointAdm"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"7"}},[_c('v-text-field',{attrs:{"label":"Nome do Pacote","rules":_vm.$store.state.formRules.required},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('v-text-field-money',{attrs:{"label":'Preço / Valor',"properties":{
                                        rules: _vm.$store.state.formRules.required,
                                        prefix:
                                            _vm.formData.price && _vm.formData.price.length ? 'R$' : '',
                                        readonly: false,
                                        disabled: false,
                                        // formData.id && !parseInt(formData.can_delete),
                                        outlined: false,
                                        clearable: true,
                                        placeholder: ' ',
                                    },"options":{
    locale: 'pt-BR',
    length: 11,
    precision: 2,
    empty: null,
}},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleChkpointAdm}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":_vm.saveCheckpoint}},[_vm._v(" Salvar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }